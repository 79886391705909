/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;
@tailwind components;

.page-header {
    @apply bg-card flex flex-0 flex-col border-b p-6 dark:bg-transparent sm:flex-row sm:items-center sm:justify-between sm:px-10 sm:py-8;
}

.panel-title {
    @apply flex items-center gap-2 px-8 py-4;
}

.panel-content {
    @apply px-8 py-6;
}

.panel-footer {
    @apply flex justify-end px-8 py-4;
}

.paginator-absolute {
    @apply z-10 border-b bg-gray-50 px-4 dark:bg-transparent sm:sticky sm:inset-x-0 sm:bottom-0 sm:border-b-0 sm:border-t;
}

.btn-sm {
    @apply h-8 min-h-8 w-auto px-4 text-sm #{'!important'};
}
