/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 0 !important;
}

.default-snackbar button {
    color: #e2e8f0 !important;
}

.default-snackbar {
    background-color: #475569;
    color: #fff;
}

.mat-button-toggle-group .mat-button-toggle {
    border: 1px solid #94a3b8 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled {
    .mat-mdc-select-disabled,
    .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
        @apply text-gray-700 #{'!important'};
    }

    .mat-mdc-text-field-wrapper {
        @apply bg-slate-200 #{'!important'};
    }
}
